<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div :class="`modal ${size} custom-scroll`">
        <div class="col-12 close" @click="close()">
          <img src="../assets/icons/close.svg" alt="" />
        </div>
        <slot name="content" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    size: {
      type: String,
      default: "big",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style  lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(33, 33, 33, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  position: absolute;
  overflow-y: auto;
  margin: auto;
  flex-direction: column;
  padding: 24px;
  border-radius: 6px;
  width: 90%;
  &.big {
    max-width: 1039px;
    min-height: 607px;
    max-height: 90vh;
    .close {
      position: absolute;
      right: 37px;
      top: 25px;
    }
  }
  &.small {
    .close {
      display: none;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>  ;